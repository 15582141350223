import React, { useState } from 'react';

import { useNavigate} from 'react-router-dom';

import { Common } from '../../Utils/Common'
import { useBarcode } from 'react-barcodes';

import "./popupcodebar.css";

const PopUpcodeBarPay = (props) => {

    const navigate =  useNavigate();  

    // const { inputRef } = useBarcode({
    //     value: props.isAddMode ? '0000|0000' : `Ab|0|0|0|0|${props.cxcid}|${props.imp}|0`,
    //     options: {
    //       background: '#FD9913',
    //       width: 1,
    //       height: 50
    //     }
    // });

    const { inputRef } = useBarcode({
        value: props.isAddMode ? '0' : `${props.idpay}${props.cxcid}`,
        options: {
          background: '#FD9913',
          width: 1,
          height: 50
        }
    });

    const onClickDomEventbtnPayByClibfrompopup = (e, id, cxcid) => {
        e.preventDefault();
        navigate(`/paymentByClib/${id}/${cxcid}`, { replace: true });        
    }

    const onClickDomEventbtnPayByTapifrompopup = (e, id, idpay) => {
        e.preventDefault();
        navigate(`/onSitePaymentByTapi/${id}/${idpay}`, { replace: true });        
    }

    const onClickDomEventbtnClosefrompopup = (e) => {
        props.togglePop();
    }  

    return (
        <div className='popup'>           
            <div className='popup_inner'>
                <div className="popup_header">  
                    <h4>Referencía</h4>
                </div>
                <div className="popup_body">  
                    {
                        props.idpay == 3 ? 
                            <div className="alert alert-info" role="alert" style={{textAlign: 'center'}}>
                                Ultimo pago a realizar!
                            </div> : null
                    }                                                    
                    <div className='row'>
                        <div className='col' style={{textAlign:'center', fontSize:'10px'}}>
                            <p>Pago: {Common.currencyFormat(props.imp)}</p>
                        </div>                        
                    </div>                        
                    <div className='row'>
                        <div className='col' style={{textAlign:'center', fontSize:'10px'}}>
                            <p>Transacción: $15.00</p>
                        </div>                        
                    </div>                        
                    <div className='row'>
                        <div className='col' style={{textAlign:'center', fontSize:'14px'}}>
                            <svg ref={inputRef} />
                        </div>                        
                    </div>                        
                </div>
                <div className="sectionBtnClose">
                    <button className="btn btn-warning" onClick={e => onClickDomEventbtnPayByClibfrompopup(e,`${props.idpay}${props.cxcid}`, props.cxcid)}>Pago con clip</button> --
                    <button className="btn btn-warning" onClick={e => onClickDomEventbtnPayByTapifrompopup(e,`${props.idpay}${props.cxcid}`, props.cxcid)}>Pago presencial</button> -- 
                    <button className="btn btn-warning" onClick={onClickDomEventbtnClosefrompopup}>Cerrar</button>
                </div>
            </div>
        </div>         
    )
}

export default PopUpcodeBarPay;